import React from "react"

import '../styles/footer.scss'

const Footer = () => (
    <section className={'footer'}>
        <p>Made with <span role="img" aria-label="heart">❤️</span> by Simon Sørensen</p>
    </section>
)

export default Footer
